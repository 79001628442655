import axios from '@/util/request'

export function getData(queryInfo) {
	return axios({
		url: `${queryInfo[1]}/get`,
		method: 'GET',
		params: {
			...queryInfo[0]
		}
	})
}

export function addCategory(form) {
	return axios({
		url: `${form[1]}/add`,
		method: 'POST',
		data: {
			...form[0]
		}
	})
}

export function editCategory(form) {
	return axios({
		url: `${form[1]}/update`,
		method: 'POST',
		data: {
			...form[0]
		}
	})
}

export function deleteCategoryById(id) {
	return axios({
		url: `${id[1]}/delete`,
		method: 'DELETE',
		params: {
			id:id[0]
		}
	})
}